/** The max amount of time we should allow polling to run in seconds */
export const SMS_POLL_MAX_TIME_SECONDS = 5000;

/** The max amount of time we should wait in between verification requests in seconds */
export const SMS_POLL_TIME_BETWEEN_REQUESTS_SECONDS = 10;

/* Setting the timer for verifying the SMS requests to 5 minutes */
export const SMS_OPT_IN_TIMER = 300000;

/** The max amount of time we should wait in between email verification requests in seconds */
export const EMAIL_POLL_TIME_BETWEEN_REQUESTS_SECONDS = 30;
