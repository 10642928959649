import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import { useCallback, useEffect, useRef, useState } from 'react';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import { Text } from 'ui-kit-v2/text/text';

import Spinner from 'ui-kit/spinner/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { resetSmsVerification } from 'state/account/account.reducers';
import {
    accountCancelSmsRequestRoutine,
    accountCheckPendingSmsRequestsRoutine,
    accountCheckSmsRequestsStatusRoutine,
    accountSendSmsConfirmationsRoutine
} from 'state/account/account.routines';
import {
    accountSmsRequestInitiatedSelector,
    accountSmsRequestsVerifiedSelector
} from 'state/account/account.selectors';

import { SMS_OPT_IN_TIMER, SMS_POLL_MAX_TIME_SECONDS } from 'const/account';
import { BREAKPOINTS } from 'const/breakpoints';

import { SmsTextNumbersToVerify } from 'types/sms';

import { addZero } from 'util/number';
import { maskPhoneNumber, removeChars } from 'util/string';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './mobile-phone-validation-setup.styles.scss';

const OnboardingMobilePhoneValidationSetupPage = () => {
    const MAX_ATTEMPTS = 2;
    const MAX_RESEND_DELAY = 30;

    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const intervalsRef = useRef<number[]>([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phoneNumber = queryParams.get('phoneNumber') || '';

    const smsRequestInitiated = useSelector(accountSmsRequestInitiatedSelector);
    const accountSmsRequestsVerified = useSelector(accountSmsRequestsVerifiedSelector);

    const [showResetButton, setShowResendButton] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [smsRequestTime, setSmsRequestTime] = useState<number>(Date.now());
    const [resentButtonCountdown, setResentButtonCountdown] = useState(0);
    const [hasApiError, setHasApiError] = useState(false);
    const [attempts, setAttempts] = useState<number>(0);

    const clearAllIntervals = () => {
        intervalsRef.current.forEach(clearInterval);
        intervalsRef.current = [];
    };

    const cancelSmsRequest = useCallback(
        ({ onSuccess }: { onSuccess: () => void }) => {
            dispatch(
                accountCancelSmsRequestRoutine.trigger({
                    onSuccess: () => {
                        dispatch(resetSmsVerification());
                        onSuccess();
                    },
                    onFailure: () => {
                        setIsResending(false);
                    }
                })
            );
        },
        [dispatch]
    );

    const startPolling = useCallback(() => {
        const intervalId = window.setInterval(() => {
            dispatch(
                accountCheckSmsRequestsStatusRoutine.trigger({
                    onFailure: () => setHasApiError(true)
                })
            );
        }, SMS_POLL_MAX_TIME_SECONDS);

        intervalsRef.current.push(intervalId);
    }, [dispatch]);

    const sendSms = useCallback(() => {
        clearAllIntervals();

        const phoneNumObj: SmsTextNumbersToVerify = {
            [removeChars(phoneNumber)]: ['RefillReminderText']
        };

        cancelSmsRequest({
            onSuccess: () => {
                dispatch(
                    accountSendSmsConfirmationsRoutine.trigger({
                        phoneNumbers: phoneNumObj,
                        onSuccess: () => {
                            dispatch(
                                accountCheckPendingSmsRequestsRoutine.trigger({
                                    onSuccess: () => {
                                        startPolling();
                                    }
                                })
                            );
                        },
                        onFailure: () => {
                            setIsResending(false);
                            setHasApiError(true);
                        }
                    })
                );
            }
        });
    }, [cancelSmsRequest, dispatch, phoneNumber, startPolling]);

    const handleResend = () => {
        if (attempts >= MAX_ATTEMPTS) {
            return;
        }
        setAttempts((prev) => prev + 1);
        setIsResending(true);
        setResentButtonCountdown(MAX_RESEND_DELAY);
        setSmsRequestTime(Date.now());
        sendSms();
    };

    const handleSkip = () => {
        clearAllIntervals();
        navigate('/secure/auth');
    };

    useEffect(() => {
        if (accountSmsRequestsVerified) {
            // navigate to the next page
            clearAllIntervals();
            alert('navigate to the next page - not implemented yet');
        } else if (!smsRequestInitiated || !phoneNumber) {
            navigate('/secure/onboarding/mobile-phone-setup');
        }
    }, [phoneNumber, accountSmsRequestsVerified, smsRequestInitiated, dispatch]);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (resentButtonCountdown > 0) {
            timer = setInterval(() => {
                setResentButtonCountdown((prev) => prev - 1);
            }, 1000);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [resentButtonCountdown]);

    useEffect(() => {
        if (phoneNumber) sendSms();
    }, [phoneNumber]);

    const renderCountDown = useCallback(
        ({ minutes, seconds, completed }: CountdownTimeDelta) => {
            if (completed) {
                setShowResendButton(true);
                clearAllIntervals();
            }

            return (
                <div
                    className={`mobile-phone-validation-setup-page__countdown__container ${
                        width <= BREAKPOINTS.sm ? 'mobile' : ''
                    }`}
                >
                    <>
                        <Text
                            size="num-4x"
                            weight={700}
                            lineHeight={width <= BREAKPOINTS.sm ? 32.2 : 36.8}
                            color={completed ? 'silver' : 'dusk'}
                            className="mobile-phone-validation-setup-page__countdown__timer"
                        >
                            {minutes}:{addZero(seconds)}
                        </Text>
                        <Spinner isVisible={!completed} t={t} fullOverlay={false} />
                    </>
                </div>
            );
        },
        [t, width]
    );

    return (
        <WorkflowLayout
            className="mobile-phone-validation-setup-page"
            metaData={{ nodeTitle: 'Mobile Phone Validation' }}
            isUnauthenticatedFlow={false}
        >
            <LayoutWrapperComponent
                title={t('pages.onboarding.mobilePhoneValidationSetup.title')}
                headerAlign="left"
                workflowNavigation={
                    <WorkflowNavigationSection
                        limit={5}
                        usage={4.6}
                        customColor="picton-blue"
                        handleClick={() => {
                            clearAllIntervals();
                            navigate('/secure/onboarding/mobile-phone-setup?phoneNumber=' + phoneNumber);
                        }}
                    />
                }
            >
                <div className="mobile-phone-validation-setup-page__container">
                    <div className="mobile-phone-validation-setup-page__container__information">
                        <Text size="num-2x" weight={500} lineHeight={24} color="dusk">
                            {t('pages.onboarding.mobilePhoneValidationSetup.description_one', {
                                phoneNumber: maskPhoneNumber(phoneNumber)
                            })}
                        </Text>
                        <Text size="num-2x" weight={500} lineHeight={24} color="dusk">
                            {t('pages.onboarding.mobilePhoneValidationSetup.description_two')}
                        </Text>
                    </div>

                    {hasApiError || attempts >= MAX_ATTEMPTS ? (
                        <div className="mobile-phone-validation-setup-page__countdown--error">
                            <Spinner isVisible={true} t={t} fullOverlay={false} />
                            <ToastBox variant="error-v2" icon="error-v2" backgroundColor="mandarin-with-opacity">
                                <Text size="num-1_75x">{t('pages.onboarding.mobilePhoneValidationSetup.trouble')}</Text>
                            </ToastBox>
                            <ButtonComponent
                                onClick={() => handleSkip()}
                                variant="ghost"
                                label={t('pages.onboarding.mobilePhoneValidationSetup.skip')}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="mobile-phone-validation-setup-page__countdown">
                                <Countdown
                                    key={smsRequestTime}
                                    renderer={renderCountDown}
                                    date={smsRequestTime + SMS_OPT_IN_TIMER}
                                />
                            </div>

                            <ToastBox icon="info" iconColor="cathams-blue" backgroundColor="alice-blue-tint">
                                <Text size="num-1_75x">{t('pages.onboarding.mobilePhoneValidationSetup.info')}</Text>
                            </ToastBox>

                            <ButtonComponent
                                isVisible={showResetButton}
                                variant="ghost"
                                label={
                                    isResending && resentButtonCountdown > 0
                                        ? t('pages.onboarding.mobilePhoneValidationSetup.resend_delay', {
                                              time: resentButtonCountdown
                                          })
                                        : t('pages.onboarding.mobilePhoneValidationSetup.resend')
                                }
                                disabled={resentButtonCountdown > 0}
                                onClick={handleResend}
                            />
                        </>
                    )}
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default OnboardingMobilePhoneValidationSetupPage;

export const query = graphql`
    query OnboardingMobilePhoneValidationSetupPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
